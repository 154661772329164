/// <reference path="o365.pwa.declaration.sw.routehandlers.api.file.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiFileStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts')>("o365.pwa.modules.sw.strategies.api.file.strategy.ts");

    function initializeApiFileRouteHandler() {
        // #region - VIEW
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/pwa\/api\/file\/view\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'VIEW'
            }),
            'GET'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/pwa\/api\/view-pdf\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'PDF-VIEW'
            }),
            'GET'
        );
        // #endregion
        // #region - DOWNLOAD
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/pwa\/api\/file\/download\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'DOWNLOAD'
            }),
            'GET'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/pwa\/api\/download-pdf\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'PDF-DOWNLOAD'
            }),
            'GET'
        );
        // #endregion
        // #region - CHUNKUPLOAD
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/chunkupload\/upload\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/chunkupload\/initiate\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-INITIATE'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/chunkupload\/setcrc32\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'CHUNK-SET-CRC32'
            }),
            'POST'
        );
        // #endregion
        // #region - Deprecated file upload routes for legacy support
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/upload\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/chunkupload2\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'OLD-CHUNK-UPLOAD-2'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/chunkupload\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'OLD-CHUNK-UPLOAD'
            }),
            'POST'
        );
        self.o365.registerRoute(
            new RegExp('(?:\/nt)?\/api\/file\/uploadprogress\/.*'),
            new ApiFileStrategy({
                apiFileRoute: 'UPLOAD-PROGRESS'
            }),
            'POST'
        );
        // #endregion
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.api.file.d.ts')>({ initializeApiFileRouteHandler });
})()
